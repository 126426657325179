import { RemixBrowser } from '@remix-run/react'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { getInitialNamespaces } from 'remix-i18next'
import { i18n } from '#app/utils/i18n.server.ts'
// import enPrompts from './locales/en/prompts.ts'
import en from './locales/en/translations.ts'
// import frPrompts from './locales/fr/prompts.ts'
import fr from './locales/fr/translations.ts'
// import { measure } from './utils/measure.ts'

// if (ENV.MODE === 'production' && ENV.SENTRY_DSN) {
// 	import('./utils/monitoring.client.tsx').then(({ init }) => init())
// }

// measure('entry.client#hydrate', async () => {
// 	let instance = createInstance().use(initReactI18next).use(LanguageDetector)
// 	await instance.init({
// 		supportedLngs: ['en', 'fr'],
// 		fallbackLng: 'en',
// 		react: { useSuspense: false },
// 		ns: getInitialNamespaces(),
// 		detection: { order: ['cookie', 'htmlTag'], caches: [] },
// 		resources: {
// 			en: { translation: en, prompts: enPrompts },
// 			fr: { translation: fr, prompts: frPrompts },
// 		},
// 		interpolation: { escapeValue: false },
// 	})

// 	return hydrate(instance)
// })

// function hydrate(instance: i18n) {
// 	startTransition(() => {
// 		hydrateRoot(
// 			document,
// 			<I18nextProvider i18n={instance}>
// 				<RemixBrowser />
// 			</I18nextProvider>,
// 		)
// 	})
// }

async function hydrate() {
	await i18next
		.use(initReactI18next) // Tell i18next to use the react-i18next plugin
		.use(LanguageDetector) // Setup a client-side language detector
		.use(Backend) // Setup your backend
		.init({
			...i18n, // spread the configuration
			// This function detects the namespaces your routes rendered while SSR use
			ns: getInitialNamespaces(),
			resources: {
				en: { translation: en },
				fr: { translation: fr },
			},
			detection: {
				// Here only enable htmlTag detection, we'll detect the language only
				// server-side with remix-i18next, by using the `<html lang>` attribute
				// we can communicate to the client the language detected server-side
				order: ['cookie', 'htmlTag'],
			},
		})

	startTransition(() => {
		hydrateRoot(
			document,
			<I18nextProvider i18n={i18next}>
				<StrictMode>
					<RemixBrowser />
				</StrictMode>
			</I18nextProvider>,
		)
	})
}

if (window.requestIdleCallback) {
	window.requestIdleCallback(hydrate)
} else {
	// Safari doesn't support requestIdleCallback
	// https://caniuse.com/requestidlecallback
	window.setTimeout(hydrate, 1)
}
